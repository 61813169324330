.u-text--left {
  text-align: left;
}

.u-text--right {
  text-align: right;
}

.u-text--center {
  text-align: center;
}

.u-text--uppercase {
  text-transform: uppercase;
}

.u-valign--middle {
  vertical-align: middle !important;
}
