@use 'toolkit';

html {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  background: toolkit.$backgroundColor;
  color: toolkit.$textMainColor;

  --mdc-typography-button-letter-spacing: normal;
  --mdc-typography-caption-letter-spacing: normal;
}

body {
  min-height: 100%;
  font-family: toolkit.$fontMain;
  overflow-anchor: none;
}

* {
  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {

}
