@use 'sass:math';

h1, h2, h3, h4, .t-h1, .t-h2, .t-h3, .t-h4, h5, .t-h5 {
  margin: 0;
  padding: 0;
}

h2, .t-h2 {

}

h3, .t-h3 {

}

h4, .t-h4 {

}

h5, .t-h5 {

}

p {

}

a {

}

code {

}
