@use 'toolkit';

@mixin generate-grid-utilities(
  $prefix: 'fx',
  $breakpoints: (
    'sm': '640px',
    'md': '768px',
    'lg': '1024px',
    'xl': '1280px',
  ),
  $gutters: (
    1: 1rem,
    2: 2rem,
    'bu1': toolkit.$bu,
    'bu1-5': (toolkit.$bu * 1.5),
    'bu2': (toolkit.$bu * 2),
  ),
  $grid-columns: 12,
  $grid-rows: 12,
  $auto-flows: ('row', 'column', 'dense'),
  $alignments: ('start', 'end', 'center', 'stretch'),
  $justifications: ('start', 'end', 'center', 'space-between', 'space-around'),
  $auto-sizes: ('auto', 'min-content', 'max-content'),
  $aspect-ratios: ('16/9', '4/3', '1/1'),
  $gaps: (
    '1': 0.25rem,
    '2': 0.5rem,
    '3': 0.75rem,
    '4': 1rem,
    'bu1': toolkit.$bu,
    'bu1-5': (toolkit.$bu * 1.5),
    'bu2': (toolkit.$bu * 2),
  ),
  $sizes: (
    'xs': 20rem,
    'sm': 30rem,
    'md': 40rem,
    'lg': 50rem,
    'xl': 60rem,
  ),
  $z-indices: (0, 10, 20, 30, 40, 50)
) {
  // Base grid styles
  .#{$prefix}\:grid {
    display: grid;
  }
  .#{$prefix}\:inline-grid {
    display: inline-grid;
  }

  @each $name, $size in $gutters {
    .#{$prefix}\:gap-#{$name} {
      gap: $size;
    }
  }

  @for $i from 1 through $grid-columns {
    .#{$prefix}\:grid-cols-#{$i} {
      grid-template-columns: repeat($i, 1fr);
    }
  }

  @for $i from 1 through $grid-rows {
    .#{$prefix}\:grid-rows-#{$i} {
      grid-template-rows: repeat($i, 1fr);
    }
  }

  @each $name, $breakpoint in $breakpoints {
    @media (min-width: $breakpoint) {
      // Repeat all previous styles, but prefixed with the breakpoint name
      // Example:
      .#{$prefix}\:#{$name}\:grid-cols-3 {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  @each $alignment in $alignments {
    .#{$prefix}\:align-items-#{$alignment} {
      align-items: unquote($alignment);
    }
    .#{$prefix}\:align-content-#{$alignment} {
      align-content: unquote($alignment);
    }
  }

  @each $justification in $justifications {
    .#{$prefix}\:justify-items-#{$justification} {
      justify-items: $justification;
    }
    .#{$prefix}\:justify-content-#{$justification} {
      justify-content: $justification;
    }
  }

  @each $size in $auto-sizes {
    .#{$prefix}\:grid-auto-cols-#{$size} {
      grid-auto-columns: $size;
    }
    .#{$prefix}\:grid-auto-rows-#{$size} {
      grid-auto-rows: $size;
    }
  }

  @each $ratio in $aspect-ratios {
    $divider-index: str-index($ratio, '/');
    $ratio-a: str-slice($ratio, 1, $divider-index - 1);
    $ratio-b: str-slice($ratio, $divider-index + 1);
    $ratio-name: $ratio-a + '-' + $ratio-b;

    .#{$prefix}\:aspect-#{$ratio-name} > * {
      position: relative;
      width: 100%;
      padding-bottom: calc(#{$ratio-b} / #{$ratio-a} * 100%);
      > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  @each $gap, $size in $gaps {
    .#{$prefix}\:row-gap-#{$gap} {
      row-gap: $size;
    }
    .#{$prefix}\:col-gap-#{$gap} {
      column-gap: $size;
    }
  }

  @each $name, $size in $sizes {
    .#{$prefix}\:min-w-#{$name} {
      min-width: $size;
    }
    .#{$prefix}\:max-w-#{$name} {
      max-width: $size;
    }
    .#{$prefix}\:min-h-#{$name} {
      min-height: $size;
    }
    .#{$prefix}\:max-h-#{$name} {
      max-height: $size;
    }
  }

  @each $z in $z-indices {
    .#{$prefix}\:z-#{$z} {
      z-index: $z;
    }
  }

  @each $flow in $auto-flows {
    .#{$prefix}\:grid-auto-flow-#{$flow} {
      grid-auto-flow: unquote($flow);
    }
  }
}
