.c-blurred {
  filter: blur(2px);
  font-weight: 500;

  transition: filter 200ms;

  &:hover {
    filter: blur(0);
  }
}
