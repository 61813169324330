@mixin generate-positioning-utilities($prefix: "fx") {
  .#{$prefix}\:relative { position: relative; }
  .#{$prefix}\:absolute { position: absolute; }
  .#{$prefix}\:fixed { position: fixed; }
  .#{$prefix}\:sticky { position: sticky; }

  $positions: (0: 0, 1: 0.25rem, 2: 0.5rem, 3: 0.75rem, 4: 1rem);

  @each $name, $value in $positions {
    .#{$prefix}\:top-#{$name} { top: $value; }
    .#{$prefix}\:right-#{$name} { right: $value; }
    .#{$prefix}\:bottom-#{$name} { bottom: $value; }
    .#{$prefix}\:left-#{$name} { left: $value; }
  }
}
