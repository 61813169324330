$fontMain: 'Roboto', sans-serif;
$bu: 24px;
$maxWidth: 1440px;
$minWidth: 1200px;
$formWrapWidth: 560px;
$formWidth: 340px;
$identityAlpha: #3f9c35;
$identityBeta: #e3ce35;
$identityGamma: #2a5396;
$backgroundColor: #f2f2f2;
$textMainColor: #424242;
