@use 'mat';
@use 'toolkit';

$_padding: toolkit.$bu * 2;

.c-wrap {
  max-width: toolkit.$maxWidth + $_padding * 2;
  padding: 0 $_padding;
  margin: 0 auto;
  min-width: toolkit.$minWidth;
}
