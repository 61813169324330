@use 'mat';
@use 'normalize.css/normalize';
@use './variables';

@use './theme';
@use 'grid';
@use 'spacing';
@use 'typography';
@use 'positioning';
@use 'overflow';

/** base local */
@use './base/page';
@use './base/material';
@use './base/typographies';
@use './base/utils';

/** components */
@use './components/button';
@use './components/blurred';
@use './components/wrap';
@use './components/info-item';

@include mat.core();
@include mat.all-component-themes(theme.$appTheme);

@include grid.generate-grid-utilities();
@include spacing.generate-spacing-utilities();
@include typography.generate-typography-utilities();
@include positioning.generate-positioning-utilities();
@include overflow.generate-overflow-utilities();
