@use 'toolkit';

.mat-mdc-form-field {
  width: 100%;
}

.mdc-switch__icon--on {
  fill: transparent !important;
}

.c-dialog-no-padding .mat-dialog-container {
  padding: 0 !important;
  overflow: hidden;
}

.mat-snack-bar-container {
  background: transparent;
  box-shadow: none;
}

.mdc-snackbar__label {
  padding: 0 !important;

  box-shadow: none !important;
  background: transparent !important;
}

.mdc-snackbar__surface {
  padding: 0 !important;

  box-shadow: none !important;
  background: transparent !important;
}

.c-soft-elevation {
  box-shadow: 0px 11px 15px -7px rgb(131 131 131 / 20%), 0px 24px 38px 3px rgb(133 133 133 / 14%), 0px 9px 46px 8px rgb(134 134 134 / 12%) !important;
}

.c-login-backdrop {
  background: toolkit.$backgroundColor;
}

.c-login-pane {
  .mdc-dialog__surface {
    box-shadow: none;
    background: toolkit.$backgroundColor;
  }
}
