@use 'mat';

.c-info-item {}

.c-info-item_label {
  line-height: 2em;

  color: #666;

  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}

.c-info-item_value {
  display: block;
  line-height: 1.8em;
  padding-top: 4px;

  font-weight: 500;
  font-size: 1em;

  &.is-list {
    display: inline-block;
  }
}

.c-info-item_badge {
  display: inline-block;
  padding: 0 12px;
  vertical-align: middle;
  line-height: 26px;

  background-color: #e0e0e0;
  border: none;
  color: #868686;
  border-radius: 2px;

  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;

  mat-icon {
    vertical-align: middle;
    font-size: 15px;
    line-height: 14px;
    width: 15px;
    height: 15px;
    color: #000;
    display: none;
  }

  &.is-active {
    background: #c1f2b1;
    color: #278608;

    mat-icon {
      display: inline-block;
    }

  }

}

.c-info-item_more {
  font-size: 14px;
  line-height: 22px;
}

.c-info-item_more-title {
  line-height: 2em;
  color: #666;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}

.c-info-item_more-value {
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 6px;
}
